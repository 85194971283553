/*
 * @Description: 接口拦截器
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-09 15:54:07
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-28 18:31:24
 */
import store from '../store'
import axios from 'axios'
// const BASEURL = process.env.NODE_ENV === 'production' ? '' : '/api'
// console.log('BASEURL=', BASEURL)
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 8000,
  withCredentials: true, // 允许携带cookie
})
// 添加请求拦截器
service.interceptors.request.use(function (config) {
  console.log("请求拦截器", config)
  // 在发送请求之前做些什么
  store.commit('changeSpinning', true)
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  // A0230：用户登录已过期
  if (response.data.code=='A0230'){
    let grubURL = `${process.env.VUE_APP_BASE_URL}/sso/login?return-url=${encodeURIComponent(window.location.href)}`;
    window.location.href =  grubURL;
  // A0301：访问未授权
  } else if (response.data.code=='A0301') {
    window.location.href =  `${process.env.VUE_APP_BASE_WEB_URL}/#/noPermission`;
  }

  // 1、调接口，有成功接口数据 通过code判断 A0000
  //     有，用户一定已登录 
  //     没有，用户未登录
  store.commit('changeSpinning', false)
  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default service